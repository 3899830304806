import Roblox, { AXAnalyticsService } from 'Roblox';
import sendAXTracking from './sendAXTracking';
import AXAnalyticsConstants from './AXAnalyticsConstants';
import useAXTrackView from './useAXTrackView';
import useIsElementVisible from './useIsElementVisible';
import reportAXError from './reportAXError';
import { AXSendTrackingActionType } from './types';

const AnalyticsService: typeof AXAnalyticsService = {
  sendAXTracking,
  useAXTrackView,
  useIsElementVisible,
  AXAnalyticsConstants,
  reportAXError
};

Roblox.AXAnalyticsService = AnalyticsService;
Roblox.AXSendTrackingActionType = AXSendTrackingActionType;
